import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"payments",
        "id":"purchase_id",
        "nameSingle":"purchase",
        "nameMultiple":"purchases",
        "options":[],
        "fields":{
            "field0":{
                "name":"Type",
                "field":"purchase_type",
                "type":"Display",
                "required":false,
                "list":true,
            },
            "field1":{
                "name":"Amount",
                "field":"purchase_amount",
                "type":"Display",
                "required":false,
                "list":true,
            },
            "field2":{
                "name":"Date",
                "field":"purchase_date",
                "type":"Date",
                "list":true
            },
            "field3":{
                "name":"User",
                "field":"user_email",
                "type":"Display",
                "list":true
            },
            "field4":{
                "name":"Platform",
                "field":"purchase_platform",
                "type":"Display",
                "list":true
            },
            "field5":{
                "name":"Status",
                "field":"purchase_state",
                "type":"Display",
                "list":true
            },
        
        }
    }
}